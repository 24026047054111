import { NormalizedPost } from '../../external/common/types';
import { AppData } from '../../viewer.app';
import blocksModel from './model';

export default blocksModel.createController(({ appData }) => ({
  pageReady: async () => {},
  exports: {
    ...blocksModel.getExports(),
    getPost: async (): Promise<NormalizedPost> => {
      const { post } = await (appData as AppData).fetchPostPageRenderModel();
      return post;
    },
  },
}));
