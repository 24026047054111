import { getPostPage } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import {
  createAction,
  urijs,
  getCategoryIds,
  EXPERIMENTS,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { getLanguageCode } from '../selectors/locale-selectors';
import { getDemoPosts } from '../services/demo-posts';
import getEnvironment from '../services/get-environment';
import { normalizePost, normalizePostV3 } from '../services/post-utils';
import { encodeURISlug } from '../services/slug';

export const FETCH_POST_REQUEST = 'post/FETCH_REQUEST';
export const FETCH_POST_SUCCESS = 'post/FETCH_SUCCESS';
export const FETCH_POST_FAILURE = 'post/FETCH_FAILURE';

export const fetchPostRequest = createAction(FETCH_POST_REQUEST);
export const fetchPostSuccess = createAction(FETCH_POST_SUCCESS);
export const fetchPostFailure = createAction(FETCH_POST_FAILURE);

const makeRequest = (
  postSlug,
  {
    includeDraft,
    request,
    instance,
    httpClient,
    isPlatformizedApiEnabled,
    languageCode,
  },
) => {
  if (!postSlug) {
    throw new Error('Missing postSlug');
  }

  if (isPlatformizedApiEnabled) {
    return httpClient
      .request(
        getPostPage({
          postId: decodeURI(postSlug),
          translationsName: 'main',
          languageCode,
          loadRichContent: false,
        }),
      )
      .then(({ data }) => {
        return {
          ...normalizePostV3(data.postPage.post),
          tags: data.postPage.tags ?? [],
        };
      });
  }

  const fieldsets = `categories,owner,likes,content,subscriptions,tags,seo,translations,urls`;
  const requestUrl = urijs(
    `/_api/posts/${!includeDraft ? 'content/' : ''}${postSlug}`,
  ).query({
    fieldsets,
  });
  return request(requestUrl, { instance });
};

export function fetchPost(postSlug, { includeDraft, instance } = {}) {
  return (dispatch, getState, { request, httpClient, wixCodeApi }) => {
    const promise = preFetchPost(postSlug, {
      includeDraft,
      instance,
    })(dispatch, getState, { request, httpClient });
    return completeFetchPost(postSlug, promise)(dispatch, getState, {
      httpClient,
      wixCodeApi,
    });
  };
}

export const preFetchPost =
  (postSlug, { includeDraft, instance } = {}) =>
  (dispatch, getState, { request, httpClient }) => {
    postSlug = encodeURISlug(postSlug);
    dispatch(fetchPostRequest({ postSlug }));
    const state = getState();
    const isPlatformizedApiEnabled = isExperimentEnabled(
      state,
      EXPERIMENTS.IS_PLATFROM_API_FOR_POST_PAGE_ENABLED,
    );
    const languageCode = getLanguageCode(state);

    return makeRequest(postSlug, {
      includeDraft,
      request,
      instance,
      httpClient,
      isPlatformizedApiEnabled,
      languageCode,
    });
  };

export const completeFetchPost =
  (postSlug, preFetchResult) =>
  async (dispatch, getState, { wixCodeApi, httpClient }) => {
    postSlug = encodeURISlug(postSlug);

    try {
      let post;
      let capturedError;

      try {
        post = await preFetchResult;
      } catch (e) {
        capturedError = 'response' in e ? e.response : e;
        if (
          capturedError?.status === 404 &&
          getEnvironment(wixCodeApi).isEditorSegment
        ) {
          const resp = await getDemoPosts({
            httpClient,
            getState,
            dispatch,
            wixCodeApi,
            query: { slugs: [postSlug] },
          });
          post = resp?.posts?.[0];
          if (post) {
            capturedError = null;
          }
        }
      }

      if (capturedError) {
        throw capturedError;
      }

      const normalizedPost = normalizePost({
        state: getState(),
        post,
        blogCategoryIds: getCategoryIds(getState()),
      });

      dispatch(fetchPostSuccess({ post: normalizedPost, postSlug }));

      return normalizedPost;
    } catch (error) {
      dispatch(fetchPostFailure({ postSlug, error }));
      throw error;
    }
  };
