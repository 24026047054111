import {
  NodeType,
  PluginContainerDataAlignment,
  TextAlignment,
  WidthType,
  DecorationType,
  LayoutType,
  Orientation,
  Crop,
  ThumbnailsAlignment,
  Type,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';

export const demoRichContent = {
  nodes: [
    {
      type: NodeType.PARAGRAPH,
      id: '3qqn6',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: "When it comes to design, the Wix Blog has everything you need to create beautiful posts that will grab your reader's attention. Check out our essential design features. ",
            decorations: [],
          },
        },
      ],
      style: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.LEFT,
          lineHeight: '1.5',
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: '4v47n',
      nodes: [],
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.IMAGE,
      id: '4htj4',
      nodes: [],
      imageData: {
        containerData: {
          width: {
            size: WidthType.CONTENT,
          },
          alignment: PluginContainerDataAlignment.CENTER,
          textWrap: true,
        },
        image: {
          src: {
            id: '6d8832_88645c6e7a4842fb83755c22e5f62165~mv2.jpg',
          },
          width: 5184,
          height: 3456,
        },
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: 'rphp',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: ' ',
            decorations: [],
          },
        },
      ],
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.HEADING,
      id: '5jeam',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Choose from several beautiful layouts',
            decorations: [],
          },
        },
      ],
      style: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      headingData: {
        level: 2,
        textStyle: {
          textAlignment: TextAlignment.LEFT,
          lineHeight: '1.38',
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: 'd658n',
      nodes: [],
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: '8ed5v',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Your blog feed comes with many responsive layouts to choose from. From the blog feed’s settings in the Editor, choose the layout that’s right for you. The ',
            decorations: [],
          },
        },
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Editorial',
            decorations: [
              {
                type: DecorationType.BOLD,
                fontWeightValue: 700,
              },
            ],
          },
        },
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: ' layout is great for readers to quickly find posts that interest them. Or you can go for a ',
            decorations: [],
          },
        },
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Side by Side',
            decorations: [
              {
                type: DecorationType.BOLD,
                fontWeightValue: 700,
              },
            ],
          },
        },
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: ' layout that lets readers scroll down and see your posts one by one. Once you choose one, click Customize Layout to tweak the settings even more.',
            decorations: [],
          },
        },
      ],
      style: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.LEFT,
          lineHeight: '1.38',
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: '2hntl',
      nodes: [],
      style: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.LEFT,
          lineHeight: '1.38',
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: 'bn7l5',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: '​​From the settings, you can also choose other things to display on your blog feed, like a Blog Menu, author name, likes and views, and more.',
            decorations: [],
          },
        },
      ],
      style: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.LEFT,
          lineHeight: '1.38',
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: 'cjhqr',
      nodes: [],
      style: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.LEFT,
          lineHeight: '1.38',
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.HEADING,
      id: '7jjc8',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Customize your post page',
            decorations: [],
          },
        },
      ],
      style: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      headingData: {
        level: 2,
        textStyle: {
          textAlignment: TextAlignment.LEFT,
          lineHeight: '1.38',
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: 'dkcmp',
      nodes: [],
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: 'b12kj',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Go to the Post Page in the Editor to customize settings for your posts. Changing anything here will change the layout and design for all your posts at once. ',
            decorations: [],
          },
        },
      ],
      style: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.LEFT,
          lineHeight: '1.38',
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: '33grb',
      nodes: [],
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: 'emdqv',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Keep readers engaged by letting them leave comments and share your posts on social media. You can also display ',
            decorations: [],
          },
        },
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Related Posts',
            decorations: [
              {
                type: DecorationType.BOLD,
                fontWeightValue: 700,
              },
            ],
          },
        },
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: ' and ',
            decorations: [],
          },
        },
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Tags',
            decorations: [
              {
                type: DecorationType.BOLD,
                fontWeightValue: 700,
              },
            ],
          },
        },
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: ' at the bottom of your posts to help readers discover more of your posts and keep reading.',
            decorations: [],
          },
        },
      ],
      style: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.LEFT,
          lineHeight: '1.38',
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: 'bm5c7',
      nodes: [],
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.GALLERY,
      id: 'c1tne',
      nodes: [],
      galleryData: {
        containerData: {
          width: {
            size: WidthType.CONTENT,
          },
          alignment: PluginContainerDataAlignment.CENTER,
          spoiler: {
            enabled: false,
          },
          textWrap: true,
        },
        items: [
          {
            image: {
              media: {
                src: {
                  url: '6d8832_59b2ceca5c654477990084d2c672555f~mv2.png',
                },
                width: 3986,
                height: 4042,
              },
            },
          },
          {
            image: {
              media: {
                src: {
                  url: '6d8832_7c6eaa9901164b55bc27f8dac922a707~mv2.jpg',
                },
                width: 2667,
                height: 4000,
              },
            },
          },
          {
            image: {
              media: {
                src: {
                  url: '6d8832_121e40da176e43aaa9f733f8a0f96b2b~mv2.jpg',
                },
                width: 2666,
                height: 4000,
              },
            },
          },
        ],
        options: {
          layout: {
            type: LayoutType.GRID,
            horizontalScroll: false,
            orientation: Orientation.ROWS,
            numberOfColumns: 3,
          },
          item: {
            targetSize: 300,
            ratio: 1,
            crop: Crop.FILL,
            spacing: 20,
          },
          thumbnails: {
            placement: ThumbnailsAlignment.BOTTOM,
            spacing: 0,
          },
        },
        disableExpand: false,
        disableDownload: false,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: 'd96p1',
      nodes: [],
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
  ],
  metadata: {
    version: 1,
    createdTimestamp: '2023-10-04T11:29:45.604Z' as unknown as Date,
    updatedTimestamp: '2023-10-04T11:29:45.604Z' as unknown as Date,
    id: 'dae84095-e3b8-49fd-a4ba-695935ec73cb',
  },
  documentStyle: {},
};

export const paywallDemoRichContent = {
  nodes: [
    {
      type: NodeType.PARAGRAPH,
      id: '1ebsm',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: "When it comes to design, the Wix Blog has everything you need to create beautiful posts that will grab your reader's attention. Check out our essential design features.",
            decorations: [],
          },
        },
      ],
      style: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.LEFT,
          lineHeight: '1.38',
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: '__',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: '\n',
            decorations: [],
          },
        },
      ],
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.CENTER,
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.HEADING,
      id: '__agp7s',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Want to read more?',
            decorations: [
              {
                type: DecorationType.BOLD,
                fontWeightValue: 700,
              },
            ],
          },
        },
      ],
      headingData: {
        level: 3,
        textStyle: {
          textAlignment: TextAlignment.CENTER,
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.PARAGRAPH,
      id: '__cv2g0',
      nodes: [
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'Subscribe to {domain} to keep reading this exclusive post.',
            decorations: [],
          },
        },
        {
          type: NodeType.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: '\n',
            decorations: [],
          },
        },
      ],
      paragraphData: {
        textStyle: {
          textAlignment: TextAlignment.CENTER,
        },
        indentation: 0,
      },
    },
    {
      type: NodeType.BUTTON,
      id: '__c3gvh',
      nodes: [],
      buttonData: {
        containerData: {
          width: {
            size: WidthType.ORIGINAL,
          },
          alignment: PluginContainerDataAlignment.CENTER,
          textWrap: true,
        },
        type: Type.ACTION,
        styles: {
          border: {
            width: 0,
            radius: 0,
          },
          colors: {
            text: '#FFFFFF',
            border: '#0261FF',
            background: '#0261FF',
          },
        },
        text: 'Subscribe Now',
      },
    },
  ],
  metadata: {
    version: 1,
    createdTimestamp: '2023-10-19T11:32:29.405Z' as unknown as Date,
    updatedTimestamp: '2023-10-19T11:32:29.405Z' as unknown as Date,
    id: 'f57f3c34-dbf5-4421-bce3-afb906e6aec2',
  },
  documentStyle: {},
};
