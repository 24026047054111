import type { InitAppForPageFn, IPlatformAPI } from '@wix/yoshi-flow-editor';
import type { PostPageRenderModel } from './external/blocks-widget/post-page-render-model';
import { fetchInitialData } from './viewer/init';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  _,
  flowAPI,
) => {
  let data: Promise<PostPageRenderModel> | undefined;

  return {
    subjects: makeSubjectsForAppData(platformApis),
    fetchPostPageRenderModel: async () => {
      data ??= fetchInitialData({
        wixCodeApi,
        initParams,
        flowAPI,
      });

      return data;
    },
  };
};

export type LikeStatusChangeEvent = {
  isLiked: boolean;
  likeCount: number;
};

type SubjectMap = {
  likeStatusChanged: LikeStatusChangeEvent;
};

function makeSubjectsForAppData(platformApis: IPlatformAPI) {
  function makeSubject<S extends keyof SubjectMap>(subject: S) {
    return {
      next: (payload: SubjectMap[S]) =>
        platformApis.pubSub.publish(subject, payload, true),
      subscribe: (
        callback: (next: {
          data: SubjectMap[S];
          name: typeof subject;
          origin: 'worker';
        }) => void,
      ) => {
        const id = platformApis.pubSub.subscribe(subject, callback, true);

        return {
          unsubscribe: () => platformApis.pubSub.unsubscribe(subject, id),
        };
      },
    };
  }

  return {
    likeStatusChanged: makeSubject('likeStatusChanged'),
  };
}

export type AppData = {
  subjects: ReturnType<typeof makeSubjectsForAppData>;
  fetchPostPageRenderModel: () => Promise<PostPageRenderModel>;
};
