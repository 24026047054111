import type { Post } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import { demoRichContent, paywallDemoRichContent } from './demo-rich-content';
import { postContentElementIds } from './elementIds';
import model from './model';

const getPostRichContentData = async ({
  isEditor,
  post,
  state,
}: {
  isEditor: boolean;
  post: Post;
  state: $w.State;
}) => {
  if (isEditor) {
    return {
      richContent:
        state.id === postContentElementIds.paidContentId
          ? paywallDemoRichContent
          : demoRichContent,
    };
  }

  return {
    richContent: post.richContent,
    paywallRichContent: post.paywallRichContent,
  };
};

export default model.createController(({ $w, flowAPI, appData }) => {
  let isPageReady = false;
  return {
    pageReady: async () => {
      if (isPageReady) {
        return;
      }

      isPageReady = true;

      const richContentViewer = $w(
        `#${postContentElementIds.richContentViewerId}`,
      ) as $w.RichContentViewer;

      const richContentViewerWithinPaidContent = $w(
        `#${postContentElementIds.richContentViewerWithinPaidContentId}`,
      ) as $w.RichContentViewer;

      const paywallRichContentViewer = $w(
        `#${postContentElementIds.paywallRichContentViewerId}`,
      ) as $w.RichContentViewer;

      const multiStateBox = $w(
        `#${postContentElementIds.multiStateBoxId}`,
      ) as $w.MultiStateBox;

      const metaSiteId =
        flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId ?? '';
      const isMobileView = flowAPI.environment.isMobile;

      const { post } = await appData?.fetchPostPageRenderModel();
      const isEditor = flowAPI.environment.isEditor;

      const { richContent, paywallRichContent } = await getPostRichContentData({
        isEditor,
        post,
        state: multiStateBox.currentState,
      });

      richContentViewer.metaSiteId = metaSiteId;
      richContentViewer.isMobileView = isMobileView;
      richContentViewer.content = richContent;

      if (post.preview && !isEditor) {
        multiStateBox.changeState(postContentElementIds.paidContentId);
      }

      richContentViewerWithinPaidContent.metaSiteId = metaSiteId;
      richContentViewerWithinPaidContent.isMobileView = isMobileView;
      richContentViewerWithinPaidContent.content = richContent;

      if (paywallRichContent) {
        paywallRichContentViewer.metaSiteId = metaSiteId;
        paywallRichContentViewer.isMobileView = isMobileView;
        paywallRichContentViewer.content = paywallRichContent;
      } else {
        paywallRichContentViewer.delete();
      }
    },
    exports: {},
  };
});
